import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Cookies from 'js-cookie';
import Button from 'helpers/Button';
import InputElement from 'ferries/helpers/InputElement';
import SocialAccount from 'User/Account/SocialAccount';
import LinkAccount from 'User/Account/LinkAccount';
import {validateLogin} from 'User/Account/validator';
import useAsync from 'User/helpers/hooks/useAsync';
import {loginUser} from 'providers/user/auth';
import {setUserLoggedinMessage} from 'utils/session';
import {boardingRoute, recoverPasswordRoute} from 'User/helpers/routes';
import {isProduction} from 'helpers/stableValues';
import {userAccountTranslations} from 'User/helpers/translations';

const nameEmail = 'username';
const namePassword = 'password';
const {
  loginTitle, loginSubtitle, usingEmail, login, forgotPassword,
  notAccount, register, email: emailTr, password: passwordTr
} = userAccountTranslations;

const Login = ({isAccountPage, isModal, email: emailValue, onChangeTab}) => {
  const [email, setEmail] = useState({value: emailValue ?? '', error: []});
  const [password, setPassword] = useState({value: '', error: []});
  const {isLoading, isSuccess, data, execute} = useAsync();

  useEffect(() => {
    if (isSuccess && data) {
      const {result: {uuid, session_token}} = data;
      localStorage.setItem('user', JSON.stringify({uuid, session_token}));
      Cookies.set(
        'TP24ID',
        session_token,
        {path: '/', secure: isProduction, sameSite: 'lax'}
      );

      setTimeout(() => {
        if (isAccountPage) {
          window.location = boardingRoute;
        }

        if (isModal) {
          setUserLoggedinMessage();
          location.reload();
        }
      }, 500);
    }
  }, [isSuccess, data, isAccountPage, isModal]);

  const tabRegister = () => onChangeTab(1);

  const onChangeInput = (field, value, name) => {
    if (name === nameEmail) {
      setEmail((prev) => ({...prev, value, error: []}));
    } else if (name === namePassword) {
      setPassword((prev) => ({...prev, value, error: []}));
    }
  };

  const submit = async () => {
    const error = await validateLogin(email, password);
    if (error) {
      setEmail((prev) => ({...prev, error: error?.email ?? []}));
      setPassword((prev) => ({...prev, error: error?.password ?? []}));
    } else {
      await execute(loginUser({
        email: email.value,
        password: password.value,
        provider: 'brand',
        providerUserID: '',
        accessToken: ''
      }));
    }
  };

  return (
    <>
      <div className="display-4 font-bold">{loginTitle}</div>
      <div className="mb-6">{loginSubtitle}</div>
      <SocialAccount
        isAccountPage={isAccountPage}
        isModal={isModal}
        btnGoogleAccountId="btn-google-account-login"
      />
      <div className="my-4 text-center text-muted">- {usingEmail} -</div>
      <InputElement
        name={nameEmail}
        label={emailTr}
        field={email}
        inputProps={{className: 'lastpass'}}
        onChangeField={onChangeInput}
      />
      <InputElement
        name={namePassword}
        label={passwordTr}
        field={password}
        inputProps={{type: 'password', className: 'lastpass'}}
        onChangeField={onChangeInput}
      />
      <Link
        to={recoverPasswordRoute}
        target="_blank"
        className="text-small d-block mb-6"
      >
        {forgotPassword}
      </Link>
      <Button
        aria-label={login}
        status="primary"
        className="w-1-1"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={submit}
      >
        {login}
      </Button>
      <LinkAccount
        text={notAccount}
        label={register}
        onClick={tabRegister}
      />
    </>
  );
};

Login.propTypes = {
  isAccountPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  email: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired
};

export default Login;
