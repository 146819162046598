import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Button from 'helpers/Button';
import InputElement from 'ferries/helpers/InputElement';
import SocialAccount from 'User/Account/SocialAccount';
import PasswordState from 'User/helpers/components/PasswordState';
import LinkAccount from 'User/Account/LinkAccount';
import {validateRegister} from 'User/Account/validator';
import {testPassword} from 'User/helpers/validator';
import {showNotification} from 'helpers/Notifications';
import {registerUser} from 'providers/user/auth';
import useAsync from 'User/helpers/hooks/useAsync';
import {loginUser} from 'providers/user/auth';
import {isProduction} from 'helpers/stableValues';
import eyeIcon from 'User/helpers/icons/eye.svg';
import {userAccountTranslations, userPassengerTranslations} from 'User/helpers/translations';

const firstNameName = 'firstName';
const lastNameName = 'lastName';
const emailName = 'email';
const passwordName = 'password';
const typeText = 'text';
const typePassword = 'password';

const {
  registerTitle, registerSubtitle, register, haveAccount, usingEmail, login,
  email: emailTr, password: passwordTr
} = userAccountTranslations;

const {
  firstName: firstNameTr, lastName: lastNameTr
} = userPassengerTranslations;

const Register = ({isAccountPage, isModal, email: emailValue, onChangeTab}) => {
  const [firstName, setFirstName] = useState({value: '', error: []});
  const [lastName, setLastName] = useState({value: '', error: []});
  const [email, setEmail] = useState({value: emailValue ?? '', error: []});
  const [password, setPassword] = useState({
    type: typePassword, value: '', error: [], score: 0,
    lowerCase: false, upperCase: false, numbers: false, symbol: false, length: false
  });
  const {isLoading, execute} = useAsync();

  const tabLogin = () => {
    onChangeTab(0);
  };

  const onChangeInput = (field, value, name) => {
    if (name === firstNameName) {
      setFirstName((prev) => ({...prev, value, error: []}));
    } else if (name === lastNameName) {
      setLastName((prev) => ({...prev, value, error: []}));
    } else if (name === emailName) {
      setEmail((prev) => ({...prev, value, error: []}));
    } else if (name === passwordName) {
      setPassword((prev) => ({
        ...prev,
        value,
        error: [],
        ...testPassword(value)
      }));
    }
  };

  const onClickImg = () => {
    setPassword((prev) => ({
      ...prev,
      type: prev.type === typeText ? typePassword : typeText
    }));
  };

  const submit = async () => {
    const error = await validateRegister(firstName, lastName, email);
    const passwordIsInvalid = [
      password.lowerCase, password.upperCase, password.numbers, password.symbol, password.length
    ].includes(false);

    if (error || passwordIsInvalid) {
      setFirstName((prev) => ({...prev, error: error?.firstName ?? []}));
      setLastName((prev) => ({...prev, error: error?.lastName ?? []}));
      setEmail((prev) => ({...prev, error: error?.email ?? []}));
    } else {
      const {data} = await execute(registerUser({
        email: email.value,
        password: password.value, 
        passwordConfirmation: password.value,
        firstName: firstName.value,
        lastName: lastName.value
      }));

      if (isAccountPage) {
        showNotification(data?.result?.message);
        [firstNameName, lastNameName, emailName, passwordName].forEach((text) => {
          onChangeInput('', '', text);
        });
        tabLogin();
      }

      if (isModal) {
        try {
          const {body: {response: {result: {uuid, session_token}}}} = await loginUser({
            email: email.value,
            password: password.value,
            provider: 'brand',
            providerUserID: '',
            accessToken: '',
            step: 'payment'
          });

          localStorage.setItem('user', JSON.stringify({uuid, session_token}));
          Cookies.set(
            'TP24ID',
            session_token,
            {path: '/', secure: isProduction, sameSite: 'lax'}
          );
        } catch {
          return;
        }
      }

    }
  };

  return (
    <>
      <div className="display-4 font-bold">{registerTitle}</div>
      <div className="mb-6">{registerSubtitle}</div>
      <SocialAccount
        isAccountPage={isAccountPage}
        isModal={isModal}
        btnGoogleAccountId="btn-google-account-regiter"
      />
      <div className="my-4 text-center text-muted">- {usingEmail} -</div>
      <InputElement
        name={firstNameName}
        label={firstNameTr}
        field={firstName}
        onChangeField={onChangeInput}
      />
      <InputElement
        name={lastNameName}
        label={lastNameTr}
        field={lastName}
        onChangeField={onChangeInput}
      />
      <InputElement
        name={emailName}
        label={emailTr}
        field={email}
        onChangeField={onChangeInput}
      />
      <InputElement
        name={passwordName}
        label={passwordTr}
        field={password}
        inputProps={{type: password.type}}
        image={{
          src: eyeIcon,
          width: 16,
          height: 16,
          style: {
            top: '12px', right: '30px', backgroundColor: 'white',
            height: '90%', cursor: 'pointer'
          },
          onClick: onClickImg
        }}
        onChangeField={onChangeInput}
      />
      {
        password.value && <PasswordState {...password} />
      }
      <Button
        aria-label={register}
        status="primary"
        className="w-1-1"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={submit}
      >
        {register}
      </Button>
      <LinkAccount
        text={haveAccount}
        label={login}
        onClick={tabLogin}
      />
    </>
  );
};

Register.propTypes = {
  isAccountPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  email: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired
};

export default Register;
